import React from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

import { SectionBlocks } from '../../components/SectionBlocks';
import { Layout } from '../../components/layout/Layout';
import { ElbilAppType, MembershipBenefitsAppType, RoutePlannerAppType } from '../../../../types/CategoryAppType';
import { SiteStructureType } from '../../../../types/siteStructureType';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import ErrorBoundary from '../../components/error-component/ErrorBoundary';
import { Hero } from './Hero';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

export const DynamicApp = ({
  data,
  appData,
}: {
  data: SiteStructureType;
  appData?: ElbilAppType | MembershipBenefitsAppType | RoutePlannerAppType;
}) => {
  const contentUrl = useWindowLocation();

  return (
    <Layout
      title={data.seoConfig?.title || data.name}
      description={data.seoConfig?.introduction || data.ingress || ''}
      gtmArgs={{
        page_type: appData.name.charAt(0).toUpperCase() + appData.name.slice(1),
        pageCategory: data.slug,
        contentId: data.name,
      }}
      isHiddenFromSearch={data?.seoConfig?.isHiddenFromSearch}
      internalSearchMetaData={{ cardType: InternalSearchCardType.CategoryApp }}
    >
      <Hero hero={appData.properties.hero} />
      <Main>
        <ErrorBoundary>
          <SectionContainer>
            <SectionBlocks content={appData.properties.sections} />
          </SectionContainer>
          <FeedbackContainer>
            <FeedbackSurvey contentUrl={contentUrl} />
          </FeedbackContainer>
        </ErrorBoundary>
      </Main>
    </Layout>
  );
};

const Main = styled.main`
  background: white;
`;

export const FullWidthSection = styled.div`
  background-color: ${nafColor.primary.moss};
  margin: auto;
  padding: 0 ${spacing.space48};
  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24};
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: ${breakpoints.xl};
  margin: auto;
  padding: ${spacing.space48} ${spacing.space48};
  flex-direction: column;
  row-gap: ${spacing.space120};

  @media (max-width: ${breakpoints.xl}) {
    padding-top: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24};
    row-gap: ${spacing.space48};
  }
`;

const FeedbackContainer = styled.div`
  padding: 0 ${spacing.space48} ${spacing.space64} ${spacing.space48};
  margin: 0 auto;
  max-width: ${breakpoints.xl};
  box-sizing: border-box;
`;
