import React from 'react';
import { SectionHeader } from '@naf/section-header';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { HeroWithSubHeroType, SplashHeroType } from '../../../../../types/CategoryAppType';
import { HeroWithSubHero } from './HeroWithSubHero/HeroWithSubHero';
import { HeroWithTitleAndWideImage, HeroWithTitleAndWideImageType } from './HeroWithTitleAndWideImage';

interface Props {
  hero?: SplashHeroType | HeroWithSubHeroType | HeroWithTitleAndWideImageType;
}
export const Hero = ({ hero }: Props) => (
  // TODO: Update the object from elbil app in api to be flat, all keys directly under hero.
  <>
    {hero.heroVariant === 'splashHero' && (
      <StyledSectionHeader
        title={hero.title || hero.splashHero.title} // these handle the object from elbil app
        ingress={hero.ingress || hero.splashHero.ingress || undefined}
      />
    )}
    {hero.heroVariant === 'heroWithSubHero' && <HeroWithSubHero name={hero.title} heroWithSubHero={hero} />}
    {hero.heroVariant === 'heroWithTitleAndWideImage' && <HeroWithTitleAndWideImage hero={hero} />}
  </>
);

const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;
