import { useHistory } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@naf/button';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { breakpoints, spacing } from '@naf/theme';
import { FullScreenHeader, FullScreenHeadingVariant } from '@naf/section-header';
import type { SanityBlock } from '@sanity/client';
import styled from 'styled-components';
import { AdvancedImage, responsive } from '@cloudinary/react';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { CloudinaryImageType } from '../../../../../../types/blockTypes';
import { InternalLinkType } from '../../../../../../types/internalLinkType';

export interface HeroWithTitleAndWideImageType {
  heroVariant: 'heroWithTitleAndWideImage';
  header: string;
  image: CloudinaryImageType;
  mobileImage?: CloudinaryImageType;
  body?: SanityBlock[];
  ingress?: string;
  ctaButton?: {
    buttonText: string;
    data: InternalLinkType;
  };
  ctaInternalLink?: {
    text?: string;
    referenceComponent?: InternalLinkType;
  };
  ctaExternalLink?: {
    text?: string;
    link?: string;
    type: string;
    blank?: boolean;
  };
  overlay?: boolean;
}

export const HeroWithTitleAndWideImage = ({
  hero: { header, ctaInternalLink, image, mobileImage, ingress, overlay },
}: {
  hero: HeroWithTitleAndWideImageType;
}) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const history = useHistory();
  const ctaUrl = useDocumentUrlWithNode(ctaInternalLink?.referenceComponent);
  const linkButton =
    ctaInternalLink && ctaUrl ? (
      <Button
        size="large"
        variant="secondary"
        onClick={() => {
          if (ctaUrl) {
            history.push(ctaUrl);
          }
        }}
      >
        {ctaInternalLink.text}
      </Button>
    ) : undefined;

  const [mainImage, setMainImage] = useState(
    (image &&
      image.publicId &&
      cld
        .image(image.publicId)
        .resize(fill().width(1120).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const setResponsiveImage = useCallback(() => {
    const width = Math.round(window.innerWidth);
    const height = Math.round(window.innerHeight);
    const heroImage = width <= parseInt(breakpoints.s, 10) && mobileImage?.publicId ? mobileImage : image;
    if (heroImage && heroImage.publicId && typeof window !== 'undefined') {
      // Use vheight for small screens, and vw for large screens
      if (width <= parseInt(breakpoints.m, 10)) {
        setMainImage(
          cld
            .image(heroImage.publicId)
            .resize(fill().height(height).gravity(autoGravity()))
            .delivery(dpr(devicePixelRatio))
            .quality('auto:best')
            .format('auto'),
        );
      } else {
        setMainImage(
          cld
            .image(heroImage.publicId)
            .resize(fill().width(width).gravity(autoGravity()))
            .delivery(dpr(devicePixelRatio))
            .quality('auto:best')
            .format('auto'),
        );
      }
    }
  }, [cld, devicePixelRatio, mobileImage, image]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  if (mainImage) {
    return (
      <StyledFullScreenHeader
        imageOrVideo={
          <AdvancedImage
            plugins={[responsive({ steps: 200 })]}
            cldImg={mainImage}
            alt={image.alt || image.altText || image.caption}
            image={mainImage}
          />
        }
        subText={ingress}
        title={header}
        ctaButton={linkButton}
        headingVariant={FullScreenHeadingVariant.Display}
        nafBranded={false}
        overlay={overlay}
      />
    );
  }
  return <FullScreenHeader nafBranded subText={ingress} title={header} />;
};

const StyledFullScreenHeader = styled(FullScreenHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;
