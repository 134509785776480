import { SectionHeader } from '@naf/section-header';
import { Text, TextVariant } from '@naf/text';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';

import { FullWidthSection } from '../../DynamicApp';
import { HeroWithSubHeroType } from '../../../../../../types/CategoryAppType';
import { SubHeroButtons } from './SubHeroButtons';
import { Illustration } from './Illustration';
import BlockContent from '../../../../components/block-content/BlockContent';

export const HeroWithSubHero = ({ name, heroWithSubHero }: { name: string; heroWithSubHero: HeroWithSubHeroType }) => (
  <>
    <StyledSectionHeader title={name} ingress={heroWithSubHero.ingress || undefined} />
    <FullWidthSection>
      <SubHeroContent>
        <Illustration illustration={heroWithSubHero.subHero.image} />
        <SubHeroContentWrapper>
          <Title variant={TextVariant.Header1}>{heroWithSubHero.subHero.title}</Title>
          <SubHeader variant={TextVariant.SubHeader}>{heroWithSubHero.subHero.ingress}</SubHeader>
          <Body>
            <BlockContent value={heroWithSubHero.subHero.body} />
          </Body>
        </SubHeroContentWrapper>
        <SubHeroButtons
          externalLink={heroWithSubHero.subHero.externalLink}
          internalLink={heroWithSubHero.subHero.internalLink}
        />
      </SubHeroContent>
    </FullWidthSection>
  </>
);

export const SubHeroContent = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: calc(${breakpoints.xl} - ${spacing.space48} - ${spacing.space48});
  margin: auto;
`;

export const SubHeroContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 80px 0 0 0;
`;

const Title = styled(Text)`
  margin-top: 0;
  grid-column: span 7;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: 16px;
  }
`;

const SubHeader = styled(Text)`
  grid-column: span 7;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const Body = styled.div`
  grid-column: span 7;
  @media (max-width: ${breakpoints.m}) {
    grid-column: 1 / -1;
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;
